$cellWidth: 14.3%;
$cellHeight: 150px;

.zsh-cal {
    padding: 10px;
    font-size: 12px;
    position: relative;
    .oh {
      overflow: hidden;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
    }
    .zsh-cal-date {
        text-align: center;
        padding: 10px;
        font-size: 1.8em;
    }
    .zsh-cal-nav {
        padding: 10px 0;
        .left {
          button {
            margin-right: 5px;
          }
        }
    }
    .zsh-cal-content {
        .zsh-cal-conHead {
            display: flex;
            border-top: #CCC 1px solid;
            div {
                padding: 5px 0;
                width: 14.3%;
                text-align: center;
                border-left: #CCC 1px solid;
                border-bottom: #CCC 1px solid;
                &:last-child {
                    border-right: 1px solid #CCC;
                }
            }
        }
        .zsh-cal-conBody {
            .zsh-cal-conRw {
                width: 100%;
                height: $cellHeight;
                position: relative;
                .zsh-cal-conCeWr {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                }
                .zsh-cal-conCe {
                    width: $cellWidth;
                    height: $cellHeight;
                    border-left: #CCC 1px solid;
                    border-bottom: #CCC 1px solid;
                    position: relative;
                    &:last-child {
                        border-right: 1px solid #CCC;
                    }
                }
                .zsh-cal-evWr {
                    width: 100%;
                    position: absolute;
                    top: 35px;
                    left: 0;
                    z-index: 0;
                    flex: 1 1 0%;
                    height: 4em;
                    font-size: 24px;
                    .zsh-calEv-inner {
                        width: 100%;
                        height: 100px;
                        position: relative;
                    }
                    .zsh-cal-day-inner {
                        width: 100%;
                        height: 100px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: flex;
                        .zsh-cal-cliCel {
                            width: $cellWidth;
                            height: 100%;
                        }
                    }
                    .zsh-cal-evD {
                        position: relative;
                    }
                    .zsh-cal-ev {
                        position: absolute;
                        width: $cellWidth;
                        height: 24px;
                        box-sizing: border-box;
                        pointer-events: none;
                        z-index: 2;
                    }
                    .zsh-cal-ev-more {
                        top: 6.5em;
                        position: absolute;
                        font-size: 12px;
                        z-index: 3;
                        width: $cellWidth;
                        text-align: center;
                        cursor: pointer;
                        .zsh-cal-more-tg {
                            display: inline-block;
                            line-height: 2;
                            -webkit-border-radius: 3px;
                                    border-radius: 3px;
                            &:hover {
                                background-color: #EEE;
                            }
                        }
                    }
                    @for $i from 1 through 7 {
                        .zsh-cal-ev-#{$i} {
                            left: $cellWidth * $i;
                            z-index: 7 - $i;
                        }
                        .zsh-cal-ev-more-#{$i} {
                            left: $cellWidth * $i;
                        }
                    }
                    .zsh-cal-ev-0 {
                        z-index: 7;
                    }
                    .zsh-cal-btw-0 {
                        width: $cellWidth;
                    }
                    @for $i from 1 through 7 {
                        .zsh-cal-btw-#{$i} {
                            width: $cellWidth * $i;
                        }
                    }
                }
            }
        }
    }
    .calD-day {
        font-size: 0.9em;
        position: absolute;
        top: 8px;
        right: 8px;
        span {
            display: inline-block;
            text-align: center;
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin: auto;
        }
    }
    .zsh-cal-today {
        .calD-day {
            span {
                -webkit-border-radius: 50%;
                        border-radius: 50%;
                background-color: #337ab7;
                color: #FFF;
            }
        }
    }
    .zsh-cal-more-con {
        .zsh-cal-evCon {
            margin-bottom: 5px;
        }
    }
    .cal-click-con {
        width: 250px;
        position: absolute;
        font-size: 1.1em;
        background-color: #FFF;
        border: 1px solid #EEE;
        z-index: 10;
        -webkit-box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.15);
            -webkit-border-radius: 10px;
        -webkit-border-radius: 6px;
                border-radius: 6px;
        .cal-cb-wr {
            position: relative;
            padding: 15px;
        }
        .cal-cb-close {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
        }
        .cal-cb-subject {
            padding: 5px 0;
        }
        .cal-cb-date {
            padding: 5px 0;
        }
    }
    .cal-click-box {
        z-index: 11;
    }
    .cal-day-click-box {
        position: absolute;
        min-width: 200px;
        z-index: 12;
        font-size: 1.1em;
        background-color: #FFF;
        border: 1px solid #EEE;
        z-index: 10;
        -webkit-box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.15);
            -webkit-border-radius: 10px;
        -webkit-border-radius: 6px;
                border-radius: 6px;
        .cal-day-click-box-in {
            padding: 10px;
            .cal-cb-close {
                text-align: right;
                cursor: pointer;
            }
            .cal-day-click-box-con {
                padding: 10px;
                padding-top: 0px;
            }
            .cal-dy-date {
                font-weight: bold;
            }
            ul {
                padding: 0;
                margin: 0;
                padding-top: 15px;
                list-style: none;
                li {
                    padding-bottom: 10px;
                    cursor: pointer;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
.zsh-cal-evCon {
  display: block;
  height: 22px;
  padding-left: 4px;
  line-height: 20px;
  font-size: 11px;
  line-height: 2;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  box-sizing: border-box;
  pointer-events: auto;
  overflow: hidden;
  outline: none;
  background-color: #c6dafc;
  cursor: pointer;
  user-select: none;
  text-decoration: none;        
  &:hover, &:visited {
      text-decoration: none;
  }
}
.cal-click-moreEv-box {
  .cal-cb-wr {
    padding-bottom: 10px;
    div {
      margin-bottom: 2px;
    }
  }
  .zsh-cal-ev {
      margin-bottom: 5px;
      &:last-child {
          margin-bottom: 0;
      }
  }
}
@media screen and (min-width:768px) {
    .zsh-cal {
        .zsh-cal-content {
            .zsh-cal-conBody {
                .zsh-cal-conRw {
                    .zsh-cal-ev-more {
                        .zsh-cal-more-tg {
                            padding: 0 15px;
                        }
                    }
                    .zsh-cal-evWr {
                        .zsh-cal-ev {
                            padding-right: 8px;
                        }
                    }
                }
            }
        }
        .zsh-cal-evCon {
            font-size: 12px;
            padding: 0 8px;
        }
    }
}